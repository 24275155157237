import { createGlobalStyle } from '../../_snowpack/pkg/styled-components.js';
import { textConfig } from './index.js';
export default createGlobalStyle`
  :root {
    ${textConfig.__customProperties.join('')};
    /* tooltip balloon */
    --balloon-border-radius: 6px;
    --balloon-color: #333;
    --balloon-text-color: #fff;
    --balloon-font-size: 12px;
    --balloon-move: 4px;
    --shape-icon-color: #daf0ed;
  }

  html {
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  }

    .visx-tooltip {
      background-color: hsla(0, 0%, 100%, 1) !important;
    }

  * {
    scrollbar-color: #c2c2c2 transparent;

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: #c2c2c2;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
  }


  body {
    margin: 0;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, “Segoe UI”, “Oxygen”, “Ubuntu”, “Cantarell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, sans-serif;
    font-display: optional;
    font-weight: 400;
    line-height: 1;
  }

  a,
  input,
  textarea,
  button {
    color: inherit;
    font-family: 'Roboto', sans-serif;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  input,
  textarea,
  button {
    font-family: 'Roboto', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Roboto Slab', serif;
  }

  [aria-label] {
    &[data-tooltip-pos] {
      position: relative;

      // Fixing iOS Safari event issue.
      // More info at: https://goo.gl/w8JF4W
      cursor: pointer;

      &:after {
        opacity: 0;
        pointer-events: none;
        transition: opacity .18s ease-out .18s, transform .18s ease-out .18s;
        text-indent: 0;
        font-family: Roboto;
        font-weight: normal;
        font-style: normal;
        text-shadow: none;
        font-size: var(--balloon-font-size);

        background: var(--balloon-color);
        border-radius: 2px;
        color: var(--balloon-text-color);
        border-radius: var(--balloon-border-radius);
        content: attr(aria-label);
        padding: .5em 1em;
        position: absolute;
        white-space: nowrap;
        z-index: 10;
      }

      &:before {
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top-color: var(--balloon-color);
        opacity: 0;
        pointer-events: none;
        transition: opacity .18s ease-out .18s, transform .18s ease-out .18s;

        content: "";
        position: absolute;
        z-index: 10;
      }

      &:hover, &[data-tooltip-visible], &:not([data-tooltip-nofocus]):focus {
        &:before,
        &:after {
          opacity: 1;
          pointer-events: none;
        }
      }

      &[data-tooltip-break] {
        &:after {
          white-space: pre;
        }

        &[data-tooltip-length] {
          &:after {
            white-space: pre-line;
            word-break: break-word;
          }
        }
      }

      &[data-tooltip-blunt] {
        &:before,
        &:after {
          transition: none;
        }
      }

      &[data-tooltip-pos="top"],
      &[data-tooltip-pos="bottom"] {
        &:hover, &[data-tooltip-visible] {
          &:after {
            transform: translate(-50%, 0);
          }

          &:before {
            transform: translate(-50%, 0);
          }
        }
      }

      &[data-tooltip-pos*="-left"] {
        &:after {
          left: 0;
        }

        &:before {
          left: 5px;
        }
      }

      &[data-tooltip-pos*="-right"] {
        &:after {
          right: 0;
        }

        &:before {
          right: 5px;
        }
      }

      &[data-tooltip-pos*="-left"],
      &[data-tooltip-pos*="-right"] {
        &:hover, &[data-tooltip-visible] {
          &:after {
            transform: translate(0, 0);
          }

          &:before {
            transform: translate(0, 0);
          }
        }
      }

      &[data-tooltip-pos^="top"] {
        &:before,
        &:after {
          bottom: 100%;
          transform-origin: top;
          transform: translate(0, var(--balloon-move));
        }

        &:after {
          margin-bottom: 10px;
        }
      }

      &[data-tooltip-pos="top"] {
        &:before,
        &:after {
          left: 50%;
          transform: translate(-50%, var(--balloon-move));
        }
      }

      &[data-tooltip-pos^="bottom"] {
        &:before,
        &:after {
          top: 100%;
          transform: translate(0, calc(var(--balloon-move) * -1));
        }

        &:after {
          margin-top: 10px;
        }

        &:before {
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-bottom-color: var(--balloon-color);
        }
      }

      &[data-tooltip-pos="bottom"] {
        &:after,
        &:before {
          left: 50%;
          transform: translate(-50%, calc(var(--balloon-move) * -1));
        }
      }

      &[data-tooltip-pos="left"],
      &[data-tooltip-pos="right"] {
        &:hover, &[data-tooltip-visible] {
          &:after {
            transform: translate(0, -50%);
          }

          &:before {
            transform: translate(0, -50%);
          }
        }
      }

      &[data-tooltip-pos="left"] {
        &:after,
        &:before {
          right: 100%;
          top: 50%;
          transform: translate(var(--balloon-move), -50%);
        }

        &:after {
          margin-right: 10px;
        }

        &:before {
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-left-color: var(--balloon-color);
        }
      }

      &[data-tooltip-pos="right"] {
        &:after,
        &:before {
          left: 100%;
          top: 50%;
          transform: translate(calc(var(--balloon-move) * -1), -50%);
        }

        &:after {
          margin-left: 10px;
        }

        &:before {
          width: 0;
          height: 0;
          border: 5px solid transparent;
          border-right-color: var(--balloon-color);
        }
      }

      &[data-tooltip-length] {
        &:after {
          white-space: normal;
        }
      }

      &[data-tooltip-length="small"] {
        &:after {
          width: 80px;
        }
      }

      &[data-tooltip-length="medium"] {
        &:after {
          width: 150px;
        }
      }

      &[data-tooltip-length="large"] {
        &:after {
          width: 260px;
        }
      }

      &[data-tooltip-length="xlarge"] {
        &:after {
          width: 380px;

          @media screen and (max-width: 768px) {
            width: 90vw;
          }
        }
      }

      &[data-tooltip-length="fit"] {
        &:after {
          width: 100%;
        }
      }
    }
  }

  .tooltip-red {
    --balloon-color: #EF4836;
  }
`;
export const theme = {
  colors: {
    white: '#fff',
    frost: '#f3f4f6',
    frostbite: '#4c505b',
    glacier: '#8fdecb',
    label: '#9095A8',
    text: '#4C4F5A',
    error: '#EF4836',
    grey: '#ddd',
    crystal: '#8fddca',
    lightgrey: '#fafafa',
    subscription: ' #fff4e0'
  },
  dashedBorder: '2px dashed #0000001a',
  sizes: {
    headerHeight: '28px',
    xs: '500px',
    sm: '768px',
    md: '1024px',
    lg: '1600px'
  },
  zIndex: {
    growl: 999,

    get appVersionToast() {
      return this.growl + 1;
    }

  }
};