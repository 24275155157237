import { useEffect } from '../../../_snowpack/pkg/react.js';
import PropTypes from '../../../_snowpack/pkg/prop-types.js';
import { useTenant } from '../../hooks/index.js';

function DocumentTitle({
  title
}) {
  const {
    tenant
  } = useTenant();
  useEffect(() => {
    var _tenant$name;

    const tenantPart = (_tenant$name = tenant === null || tenant === void 0 ? void 0 : tenant.name) !== null && _tenant$name !== void 0 ? _tenant$name : 'Crystallize';
    const titlePart = title !== '' ? `${title} | ` : '';
    document.title = `${titlePart}${tenantPart}`;
  }, [title, tenant]);
  return null;
}

DocumentTitle.propTypes = {
  title: PropTypes.string,
  tenantName: PropTypes.string
};
DocumentTitle.defaultProps = {
  title: ''
};
export default DocumentTitle;